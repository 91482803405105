import { combineReducers } from "redux";

let tokenInitialState = {
    token: "",
};
const saveTokenReducer = (state = tokenInitialState, action) => {
    switch (action.type) {
        case "saveAccessToken": {
            return {
                token: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
let userDataInitialState = {
    userData: {
        gid: "",
        roleId: 0,
        roleName: "",
        userEmail: "",
        userFirstName: "",
        userLastName: "",
        userId: 0,
        respMsg: ""
    },
};
const storeUserDetailsReducer = (
    state = userDataInitialState,
    { type, payLoad }
) => {
    switch (type) {
        case "storeUserData": {
            return {
                userData: {
                    ...payLoad,
                },
            };
        }
        default: {
            return state;
        }
    }
};
let userInitialState = {
    gid: "",
};
const authUserReducer = (state = userInitialState, action) => {
    switch (action.type) {
        case "myIDLogin": {
            return {
                gid: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
let isUpdateResponse = { response: 0 };
const updateResponseReducer = (state = isUpdateResponse, action) => {
    switch (action.type) {
        case "updatePotential": {
            return { response: action.payLoad + state.response };
        }
        case "updateBudget": {
            return { response: action.payLoad + state.response };
        }
        case "updateManualIndirect": {
            return { response: action.payLoad + state.response };
        }
        default:
            return state;
    }
};

let uploadImageRadioInitialState = "";
const uploadImageRadioReducer = (
    state = uploadImageRadioInitialState,
    action
) => {
    switch (action.type) {
        case "radioValue": {
            return {
                value: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};

let guidValueInitialState = "";
const guidReducer = (
    state = guidValueInitialState,
    action
) => {
    switch (action.type) {
        case "guidValue": {
            return {
                value: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
let encryptedId = {
    id: "",
};
const saveUserIdReducer = (state = encryptedId, action) => {
    switch (action.type) {
        case "encryptedId": {
            return {
                id: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};

let azureToken = {
    token: "",
};
const saveAzureTokenReducer = (state = azureToken, action) => {
    switch (action.type) {
        case "saveAzureToken": {
            return {
                token: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
let detailsLink = {
    link: "",
};
const saveDetailsLinkReducer = (state = detailsLink, action) => {
    switch (action.type) {
        case "saveDetailsLink": {
            return {
                value: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
const storeMenuItems = [];

const storeLandingMenuItemsReducer = (state = storeMenuItems, action) => {
    switch (action.type) {
        case "saveLandingMenuItems": {
            return {
                menuItems: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
}

export const masterReducer = combineReducers({
    saveTokenReducer: saveTokenReducer,
    storeUserDetailsReducer: storeUserDetailsReducer,
    authUserReducer: authUserReducer,
    updateResponseReducer: updateResponseReducer,
    uploadImageRadioReducer: uploadImageRadioReducer,
    saveAzureTokenReducer: saveAzureTokenReducer,
    guidReducer: guidReducer,
    saveDetailsLinkReducer: saveDetailsLinkReducer,
    saveUserIdReducer: saveUserIdReducer,
    storeLandingMenuItemsReducer: storeLandingMenuItemsReducer,
});
