import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/breakpoint.scss";
import "./App.css";
import { LoginRoute } from "./Routes/LoginRoute";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import {
    ElectrificationRoute,
    MasterRoute,
    OtherRoute,
    QRCalibRoute,
} from "./Routes/MasterRoute";
import Header from "./Components/Header/Header";
import Loader from "./Components/Loader/Loader";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import UnauthorizedAccess from "./Components/UnauthorizedAccess/UnauthorizedAccess";
import IdleTimer from "react-idle-timer";
import {
    //authenticateUserBasedOnValidToken,
    authenticateUserBasedOnValidToken,
    validateTheUserBasedOnApi,
} from "./APIs/api_Login";
import { useMsal } from '@azure/msal-react';
import { storeUserDetailsAction, saveAccessTokenAction, saveAzureTokenAction } from "../src/Redux/Actions/actions";
export default function App() {
    const auth = useAuth();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const [isLoader, setIsLoader] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [unAuthText, setUnAuthText] = useState(
        "User not found in Smart QR System."
    );
    const { instance, accounts } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [isAuthorized, setIsAuthorized] = useState(true);
    //function sessionExpire(auth, dispatch) {

    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setDialogOpen(false);
    };
    function sessionExpire(auth, dispatch) {
        var newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
        window.history.pushState({ path: newurl }, "", `/`);
        localStorage.clear();
        sessionStorage.clear();
        if (auth !== undefined) {
            dispatch(storeUserDetailsAction(""));

            caches.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
        }
        window.location.href = "/";
    }

    const baseApiURL = window.location.origin;

    function authenticateUserWithGID(gid) {
        setIsLoader(true);
        authenticateUserBasedOnValidToken(dispatch, activeAccount.idToken, gid)
            .then((response) => {
                if (response) {
                    console.log("app", response);
                    //dispatch(saveAccessTokenAction(activeAccount.idToken));
                    setIsLoader(false);
                    setIsAuthorized(false);
                } else {
                    setIsLoader(false);
                    setIsAuthorized(false);
                    setDialogOpen(true);
                }
            })
            .catch((error) => {
                //setDialogOpen(true);
                setIsLoader(false);
            });
    }

    useEffect(() => {
        if (activeAccount && isAuthorized && !userDetails.userId) {
            setIsLoader(true);
            dispatch(saveAccessTokenAction(activeAccount.idToken));
            dispatch(saveAzureTokenAction(activeAccount.idToken));
            authenticateUserWithGID();
        } else {
            setIsLoader(false);
        }
    }, [instance, activeAccount, !userDetails.userId]);

    // QR, EL, THR, ALL
    return (
        <BrowserRouter>
            {/*<div className="ssiat-master-block">*/}
            {/*    {(auth.isLoading || isLoader) || (token != "" && userDetails.roleId == 0) ? (*/}
            {/*        <Loader />*/}
            {/*    ) : (userDetails && userDetails.roleId > 0) && auth.user ? (*/}
            {/*        userDetails.module == "EL" ? (*/}
            {/*            <>*/}
            {/*                <Header headerText="Electrification">Header Component</Header>*/}
            {/*                <main className="main-component">{ElectrificationRoute}</main>*/}
            {/*            </>*/}
            {/*        ) : userDetails.module == "QR" ? (*/}
            {/*            <>*/}
            {/*                <Header headerText="QR Caliberation">Header Component</Header>*/}
            {/*                <main className="main-component">{QRCalibRoute}</main>*/}
            {/*            </>*/}
            {/*        ) : (*/}
            {/*            <>*/}
            {/*                <Loader />*/}
            {/*            </>*/}
            {/*        )*/}
            {/*    ) : (*/}
            {/*        LoginRoute*/}
            {/*    )}*/}
            {/*</div>*/}
            <div className="ssiat-master-block">
                {userDetails && userDetails.roleId > 0 && userDetails.module === "EL" ?
                    (
                        <>
                            <Header headerText="Electrification">Header Component</Header>
                            <main className="main-component">{userDetails.roleId === 1 ? MasterRoute : ElectrificationRoute}</main>
                        </>
                    ) : userDetails && userDetails.roleId > 0 && userDetails.module === "QR" ? (
                        <>
                            <Header headerText="QR Caliberation">Header Component</Header>
                            <main className="main-component">{QRCalibRoute}</main>
                        </>
                    ) :
                        (userDetails && userDetails?.userData?.respMsg?.length > 0) ? OtherRoute 

                       : (
                            LoginRoute
                        )}
            </div>
            <div>
                <IdleTimer
                    timeout={100000 * 60 * 15}
                    onIdle={sessionExpire}
                    debounce={250}
                />
            </div>
            <>
                <Dialog
                    disableEscapeKeyDown
                    open={dialogOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Un-Authorized Error"}
                    </DialogTitle>
                    <DialogContent>
                        <UnauthorizedAccess
                        /*errorText={*/
                        /*    auth.error ? "MyID Error :" + auth.error?.stack : unAuthText*/
                        /*}*/
                        />
                    </DialogContent>
                </Dialog>
            </>
            {isLoader === true ? <Loader /> : <></>}
        </BrowserRouter>
    );
}
